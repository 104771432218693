/**
 * Icons helper (FontAwesome)
 */
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faCheckCircle,
  faUserCircle,
  faUserPlus,
  faPhone,
  faPhoneVolume,
  faAt,
  faExternalLinkAlt,
  faSearch,
  faCalendarAlt,
  faAngleDown,
  faLeaf,
  faShareAlt,
  faExternalLinkSquareAlt,
  faTh,
  faFilePdf,
  faArrowUp,
  faHeart,
  faTimesCircle,
  faFont,
  faTrash,
  faEdit,
  faRss,
  faFlag
} from '@fortawesome/free-solid-svg-icons'

const icons = {
  library: library.add(
    faCheckCircle,
    faUserCircle,
    faUserPlus,
    faPhone,
    faPhoneVolume,
    faAt,
    faExternalLinkAlt,
    faSearch,
    faCalendarAlt,
    faAngleDown,
    faLeaf,
    faShareAlt,
    faExternalLinkSquareAlt,
    faTh,
    faFilePdf,
    faArrowUp,
    faHeart,
    faTimesCircle,
    faFont,
    faEdit,
    faTrash,
    faRss,
    faFlag
  )
}


export default icons;