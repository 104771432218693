import { combineReducers } from 'redux'
import UserReducer from './userReducer'
import DeathNoticeReducer from './deathNoticeReducer'
import newCondolenceReducer from './newCondolencesReducer'
import newCommemorationsReducer from './newCommemorationsReducer'
import DeathNoticeDetailsReducer from './deathNoticeDetailsReducer'
import DeathNoticeCondolencesReducer from './deathNoticeCondolencesReducer'
import EditionsReducer from './editionsReducer'
import DeathNoticeEditionsReducer from './deathNoticeEditionReducer'
import SearchReducer from './searchReducer'

const allReducers = combineReducers({
    user: UserReducer,
    deathNotices: DeathNoticeReducer,
    newCondolences: newCondolenceReducer,
    newCommemorations: newCommemorationsReducer,
    deathNoticeDetails: DeathNoticeDetailsReducer,
    deathNoticeCondolences: DeathNoticeCondolencesReducer,
    editions: EditionsReducer,
    editionNotices: DeathNoticeEditionsReducer,
    searchResults: SearchReducer,
})

export default allReducers

