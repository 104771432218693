import React from 'react'
import styles from '../styles/app.scss'
import {Link} from 'react-router-dom'
import moment from 'moment'

const NewCondolence = (props) => {
    let user_name = props.condolence.user_name
    return (
        <li>
            <span className={styles["uk-text-medium"]}>für: <span className={styles["uk-text-bold"]}>
                <Link to={'/traueranzeige/' + props.condolence.death_notice.vname + '-' + props.condolence.death_notice.nname + '/' + props.condolence.death_notice.id}>
                    {props.condolence.death_notice.vname} {props.condolence.death_notice.nname}
                </Link>
            </span>
                {props.condolence.death_notice.wohnort.length > 0 &&
                <><br/>{props.condolence.death_notice.wohnort}</>
                }
                </span><br/>
            <span>
                <span
                    className={[styles["uk-color-gold"], styles["uk-text-bold"]].join(' ')}>{moment(props.condolence.create_time).format('LL')}</span>
                {user_name.length > 0 &&
                <> &middot; {user_name}</>
                }
            </span>
        </li>
    )
}

export default NewCondolence
