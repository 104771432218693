import React from 'react';
import styles from "../styles/app.scss";
import {Link} from "react-router-dom";
import moment from "moment";

const NewCommemorations = (props) => {
    return (
        <li>
            <span className={styles["uk-text-medium"]}>
                <span className={styles["uk-text-bold"]}>
                    <Link to={'/traueranzeige/' + props.commemoration.vname + '-' + props.commemoration.nname + '/' + props.commemoration.id} style={{color: '#333'}}>
                        {props.commemoration.vname} {props.commemoration.nname}
                    </Link>
                </span>
                {props.commemoration.wohnort.length > 0 &&
                <>, {props.commemoration.wohnort}</>
                }
            </span><br/>
            <span>
                <span className={[styles["uk-color-gold"], styles["uk-text-bold"]].join(' ')}>
                    {moment(props.commemoration.sterbetag).format('LL')}
                </span>
                &nbsp;&middot; {props.commemoration.jahrestag}. Jahresgedenken</span>
        </li>
    )
};

export default NewCommemorations;