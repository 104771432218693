import React from 'react'
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import moment from 'moment'
import LogoGray from '../../assets/images/pnp-gray.png'
import styles from '../../styles/app.scss'
import {portalUrl, portalName} from "../../helpers/config";

/**
 * show footer tag
 *
 * @returns {*}
 * @constructor
 */
const Footer = () => (
    <footer>
        <div className={styles["main-footer"]}>
            <div className={styles["uk-container"]}>
                <div data-uk-grid>
                    <div className={[styles["uk-width-1-1"], styles["uk-width-1-3@s"]].join(' ')}>
                        <a href={portalUrl} target="_blank" rel="noopener noreferrer">
                            <img src={LogoGray} alt={portalName} title={portalName}/>
                        </a>
                    </div>
                    <div className={[styles["uk-width-1-1"], styles["uk-width-1-3@s"]].join(' ')}>
                        <h3>Traueranzeigen</h3>
                        <ul className={styles["footer-menu"]}>
                            <li><Link to="/search">Anzeigen suchen</Link></li>
                            <li><a href="https://www.pnp.de/kontakt/anzeigenservice/"
                                   target="_blank" rel="noopener noreferrer">Anzeige aufgeben</a></li>
                        </ul>
                    </div>
                </div>

                <div className={styles['contact']}>
                    <div className={[styles['divider'], styles['uk-margin-medium-bottom']].join(' ')}>
                        <h3>Kontakt</h3>
                    </div>
                    <div className={styles['uk-child-width-expand@m']} data-uk-grid>
                        <div>
                            <div className={[styles['uk-flex'], styles['uk-flex-middle'], styles['uk-flex-center']].join(' ')}>
                                <div className={styles['uk-border-circle']}>
                                    <FontAwesomeIcon icon="phone" size="2x"/>
                                </div>
                                <div>
                                    <div className={[styles['uk-text-bold'], styles['uk-text-medium']].join(' ')}>
                                        <a href="https://www.pnp.de/kontakt/anzeigenservice/" target="_blank" rel="noopener noreferrer">Rufen Sie uns an</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className={[styles['uk-flex'], styles['uk-flex-middle'], styles['uk-flex-center']].join(' ')}>
                                <div className={styles['uk-border-circle']}>
                                    <FontAwesomeIcon icon="at" size="2x"/>
                                </div>
                                <div>
                                    <div className={[styles['uk-text-bold'], styles['uk-text-medium']].join(' ')}><a href="mailto:anzeigen@pnp.de">anzeigen@pnp.de</a></div>
                                    <div className={styles['uk-text-small']}>Schreiben Sie uns</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div className={styles['sub-footer']}>
            <div className={[styles['uk-container'], styles['uk-text-center']].join(' ')}>
                <div className={[styles['quicklinks'], styles['uk-margin-small-bottom']].join(' ')}>
                    <a href="https://www.pnp.de/impressum/" target="_blank" rel="noopener noreferrer">Impressum</a>&nbsp;|&nbsp;
                    <a href="https://www.pnp.de/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>&nbsp;|&nbsp;
                    <a href="javascript:window.__tcfapi('showUi', 2, function() {});" rel="noopener noreferrer">Cookie Einstellungen</a>&nbsp;|&nbsp;
                    <a href="https://www.pnp.de/allgemeine_geschaeftsbedingungen" target="_blank" rel="noopener noreferrer">Allgemeine Geschäftsbedingungen</a>&nbsp;|&nbsp;
                    <a href="https://support.pnp.de" target="_blank" rel="noopener noreferrer">Hilfe</a>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer
