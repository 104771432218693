import React from 'react';
import {NavLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from '../../styles/app.scss';
import MediaQuery from 'react-responsive'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {authAction, getEditions, logoutAction} from '../../actions';
import LogoBlack from '../../assets/images/pnp.png';
import ScrollUp from 'react-scroll-up';
import {slide as Menu} from 'react-burger-menu'

import styler from '../../helpers/styler';
import {portalName, portalUrl} from "../../helpers/config";

/**
 * class Header
 * + navigation
 * + Authentication link
 *
 * @author Alexander Jungwirth
 * @version 1.0.0.0
 *
 */
class Header extends React.Component {

  state = {
    menuOpen: false,
    submenu: {
      anzeigen: false
    }
  };

  componentWillMount() {
    this.props.getEditions();
    this.props.authAction();
  }

  scrollUpStype = {
    position: 'fixed',
    bottom: 50,
    right: 30,
    cursor: 'pointer',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'linear',
    transitionDelay: '0s',
    background: 'rgba(255,255,255,0.3)',
    borderRadius: '50%',
    zIndex: 1,
    width: '30px',
    height: '30px',
    padding: '3px 0 3px 8px',
  };

  /**
   * submenu toggler
   */
  toggleSubMenu = () => {
    this.setState({submenu: {...this.state.submenu, anzeigen: !this.state.submenu.anzeigen}});
  };

  /**
   * menu toggler
   *
   * @param state
   */
  handleMenuChange = (state) => {
    this.setState({menuOpen: state.isOpen})
  };

  /**
   * menu toggler
   */
  toggleMenu = () => {
    this.setState({menuOpen: !this.state.menuOpen})
  };

  doLogout = (event) => {
    event.preventDefault();
    this.props.logoutAction();
  }

  /**
   * login/register or logout buttons
   *
   * @returns {*}
   */
  loginButtons = () => {
    if (this.props.user.hasOwnProperty('id') && this.props.user.id !== null) {
      return (
          <>
            <li>
              <NavLink exact to="/profile" activeClassName={styles["uk-active"]} onClick={() => this.toggleMenu()}>
                Mein Profil
              </NavLink>
            </li>
            <li>
              <a href="/" onClick={this.doLogout}>
                Logout
              </a>
            </li>
          </>
      );
    } else {
      return (
          <>
            <li key={1} className="nav-item">
              <NavLink exact to="/login" activeClassName={styles["uk-active"]} onClick={() => this.toggleMenu()}>
                Login
              </NavLink>
            </li>
            <li key={2} className="nav-item">
              <NavLink exact to="/register" activeClassName={styles["uk-active"]} onClick={() => this.toggleMenu()}>
                Registrierung
              </NavLink>
            </li>
          </>
      )
    }
  };

  /**
   * edition list
   *
   * @returns {Array}
   */
  editionsList = () => {
    const editions = [];
    if (typeof this.props.editions.data !== 'undefined') {
      this.props.editions.data.forEach((edition, key) => {
        editions.push(<li key={key}><NavLink exact to={'/anzeigen/' + edition.link} onClick={() => this.toggleMenu()}>{edition.bezeichnung}</NavLink></li>);
      });
    }
    return editions;
  };

  /**
   * render method
   *
   * @returns {*}
   */
  render() {
    return (
        <header>
          <div className={styles["top-banner"]}>
            <div className={styles["uk-container"]}>
              <div className={[styles["uk-flex"], styles["uk-flex-between"], styles["uk-text-small"], styles["uk-padding-small-top"], styles["uk-padding-small-bottom"]].join(' ')}>
                <div className={styles["uk-color-gold"]}>Ein Angebot von <strong>Passauer Neue Presse</strong></div>
                <div className={styles["uk-color-gold"]}>&nbsp;</div>
              </div>
            </div>
          </div>

          <MediaQuery minDeviceWidth={960}>
            <div className={styles["logo-wrapper"]}>
              <div className={[styles["uk-container"], styles["uk-padding"]].join(' ')}>
                <div className={[styles["uk-flex"], styles["uk-flex-between"]].join(' ')}>
                  <div className={[styles["uk-width-1-2"], styles["uk-flex"], styles["uk-flex-middle"], styles["uk-margin-right"]].join(' ')}>
                    <div className={styles["uk-logo"]}>
                      <a href={portalUrl} target="_blank" rel="noopener noreferrer">
                        <img src={LogoBlack} alt={portalName} title={portalName}/>
                        <div className={styles["uk-text-small"]}>&nbsp;</div>
                      </a>
                    </div>
                  </div>
                  <div className={[styles["editions"], styles["uk-width-1-2"]].join(' ')}>

                    <ul className={[styles["uk-flex"], styles["uk-flex-wrap"], styles["uk-flex-right"], styles["uk-text-serif"], styles["uk-text-medium"]].join(' ')}>
                      {this.editionsList()}
                    </ul>

                  </div>
                </div>
              </div>
            </div>

            <nav className={[styles["uk-navbar-container"], styles["uk-navbar-transparent"], styles["navbar-beige"], styles["uk-navbar"]].join(' ')} data-uk-navbar>
              <div className={styles["uk-navbar-center"]}>

                <ul className={styles["uk-navbar-nav"]}>
                  <li><NavLink exact to="/" activeClassName={styles["uk-active"]}>Home</NavLink></li>
                  <li><NavLink exact to="/anzeigen" activeClassName={styles["uk-active"]}>Anzeigen</NavLink>
                    <div className={styles["uk-navbar-dropdown"]}>
                      <ul className={[styles["uk-nav"], styles["uk-navbar-dropdown-nav"]].join(' ')}>
                        <li><NavLink exact to="/anzeigen/all/">alle Traueranzeigen</NavLink></li>
                        <li className={styles["uk-nav-divider"]}/>
                        {this.editionsList()}
                      </ul>
                    </div>
                  </li>


                  {/*
                                <li><NavLink exact to="/branchenbuch" activeClassName={styles["uk-active"]}>Branchenbuch</NavLink></li>


                                <li><NavLink exact to="/ratgeber" activeClassName={styles["uk-active"]}>Ratgeber</NavLink>
                                    <div className={styles["uk-navbar-dropdown"]}>
                                        <ul className={[styles["uk-nav"], styles["uk-navbar-dropdown-nav"]].join(' ')}>
                                            <li><NavLink to="/ratgeber/todesfall">Im Todesfall</NavLink></li>
                                            <li><NavLink to="/ratgeber/bestattungsvorsorge">Bestattungsvorsorge</NavLink></li>
                                            <li><NavLink to="/ratgeber/nach-dem-tod">Nach dem Tod</NavLink></li>
                                        </ul>
                                    </div>
                                </li>
                                */}

                  {this.loginButtons()}

                </ul>

              </div>

            </nav>
          </MediaQuery>

          <MediaQuery maxDeviceWidth={959}>
            <Menu isOpen={this.state.menuOpen}
                  onStateChange={(state) => this.handleMenuChange(state)}
                  customBurgerIcon={false}
            >
              <li><NavLink to="/" exact onClick={() => this.toggleMenu()}>Home</NavLink></li>
              <li><span onClick={this.toggleSubMenu.bind(this)}>Anzeigen</span>
                {this.state.submenu.anzeigen &&
                <div className={styler(["uk-navbar-submenu", "uk-padding-small"])}>
                  <ul className={styler(["uk-nav", "uk-navbar-dropdown-nav"])}>
                    <li><NavLink to={'/anzeigen/all'} onClick={() => this.toggleMenu()}>alle Traueranzeigen</NavLink></li>
                    {this.editionsList()}
                  </ul>
                </div>
                }
              </li>

              {/*
                        <li><NavLink to="/branchenbuch" exact onClick={() => this.toggleMenu()}>Branchenbuch</NavLink></li>

                        <li><NavLink to="/ratgeber" exact onClick={() => this.toggleMenu()}>Ratgeber</NavLink></li>
                        */}

              <hr className={styler(["uk-margin-bottom"])}/>
              {this.loginButtons()}
            </Menu>
            <nav className={styler(["uk-flex", "uk-flex-between", "uk-flex-middle", "uk-padding-small", "nav-mobile"])}>
              <div>
                <div onClick={() => this.toggleMenu()} className={styler(["hamburger"])}/>
              </div>

              <div className={styles["uk-logo"]}>
                <a href={portalUrl} target="_blank" rel="noopener noreferrer">
                  <img src={LogoBlack} alt={portalName} title={portalName}/>
                </a>
              </div>
            </nav>

          </MediaQuery>

          <ScrollUp showUnder={160} style={this.scrollUpStype} duration={800}>
            <span><FontAwesomeIcon icon="arrow-up"/></span>
          </ScrollUp>
        </header>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    editions: state.editions,
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    authAction: authAction,
    logoutAction: logoutAction,
    getEditions: getEditions,
  }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps, null, {
  pure: false
})(Header);