import React, { Component } from 'react';
import Header from '../components/Layout/Header';
import Main from '../components/Layout/Main';
import Footer from '../components/Layout/Footer';
import styler from "../helpers/styler";

class App extends Component {
  render() {
    return (
        <div className={styler(["uk-flex", "uk-flex-column", "uk-height-1-1", "uk-min-height-1-1"])}>
            <Header/>
            <Main />
            <Footer />
        </div>
    );
  }
}

export default App;
