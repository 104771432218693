export default function deathNoticCondolencesReducer(state = {}, action) {

    switch (action.type) {
        case 'GET_NOTICE_CONDOLENCES':
            return action.payload;
        case 'NOTICE_CONDOLENCES':
            return action.payload;
        default:
            return state;
    }
}