import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { loginAction } from '../../actions'

/**
 * Login Form component
 *
 * @class LoginForm
 * @author Alexander Jungwirth
 */
class LoginForm extends Component {
  /**
   * @constructor
   *
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {}

    this.state = {
      visible: props.visible,
      email: '',
      password: '',
      remember: false,
      bee: '',
      loading: false,
      message: {
        active: false
      },
    }

    this.handleInputChange = this.handleInputChange.bind(this)

  }

  /**
   * input change handler
   *
   * @param event
   */
  handleInputChange (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  /**
   * perform login
   *
   * @param event
   */
  login (event) {
    event.preventDefault()
    this.setState({ loading: true })

    this.props.login({
      email: this.state.email,
      password: this.state.password,
      bee: this.state.bee,
    })
  }

  /**
   * render method
   *
   * @returns {*}
   */
  render () {
    return (
        <div>
          {(this.state.visible) &&
          <form className="uk-form-horizontal uk-margin-small-top uk-text-left">
            <div className="uk-margin">
              <label className="uk-form-label">E-Mail Adresse:</label>
              <div className="uk-form-controls">
                <input className="uk-input" type="text" placeholder="Ihre E-Mail Adresse" name="email" value={this.state.email} onChange={this.handleInputChange} required/>
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label">Passwort:</label>
              <div className="uk-form-controls">
                <input className="uk-input" type="password" placeholder="Ihr Passwort" name="password" value={this.state.password} onChange={this.handleInputChange} required/>
              </div>
            </div>

            <input name="bee" type="text" className="uk-hidden" value={this.state.bee} onChange={this.handleInputChange}/>

            <div className="uk-margin">
              <div className="uk-form-controls">
                <button className="uk-button uk-button-primary uk-width-1-4@m" onClick={this.login.bind(this)}>
                  {(this.props.user.loading) ? (
                      <div data-uk-spinner={'ratio: 0.6'}/>
                  ) : (
                      <span>LOGIN</span>
                  )
                  }
                </button>

                {!this.props.user.success &&
                <span className="uk-text-small" dangerouslySetInnerHTML={{ __html: this.props.user.message }}/>
                }

              </div>
            </div>

            <div className="uk-margin">
              <div className="uk-form-controls">
                <Link to="/register">noch kein Benutzerkonto?</Link> | <a href="https://id.pnp.de/passwort-vergessen/" target="_blank" title="Passwort zurücksetzen" alt="Passwort zurücksetzen"
                                                                          rel="noopener noreferrer">Passwort
                vergessen?</a>
              </div>
            </div>

            {(this.state.message.active) &&
            <div className="uk-margin">
              <div className="uk-form-controls">
                <div className={this.state.message.type} data-uk-alert>
                  <p>{this.state.message.text}</p>
                </div>
              </div>
            </div>
            }

          </form>
          }
        </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    user: state.user
  }
}

function matchDispatchToProps (dispatch) {
  return bindActionCreators({ login: loginAction }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(LoginForm)
