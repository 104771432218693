import React from 'react';
import styles from '../styles/app.scss';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {cdn} from '../helpers/config';

const imgWidth = 360;

const DeathNotice = (props) => {
    return <div className={[styles["uk-width-1-1"], styles["uk-width-1-2@s"], styles["uk-width-1-3@m"]].join(' ')}>
        <Link to={'/traueranzeige/' + props.notice.vname + '-' + props.notice.nname + '/' + props.notice.id} title={props.notice.vname + ' ' + props.notice.nname + ' - Traueranzeige'}>
            <div className={styles["death-notice"]}>
                <div className={[styles["description"], styles["uk-text-center"], styles["uk-padding-small"]].join(' ')}>
                    <h3 className={styles["name"]}>{props.notice.vname} {props.notice.nname}</h3>
                    <div className={[styles["date"], styles["uk-color-gold"]].join(' ')}>
                        {moment(props.notice.sterbetag).format('L')}
                        {(props.notice.wohnort.length > 0) &&
                        <> &middot; {props.notice.wohnort}</>
                        }
                    </div>
                </div>
                <figure>
                    <img src={cdn + props.notice.id + '&width=' + imgWidth}
                         title={props.notice.vname + ' ' + props.notice.nname + ' - Traueranzeige'}
                         alt={props.notice.vname + ' ' + props.notice.nname + ' - Traueranzeige'}
                    />
                </figure>
            </div>
        </Link>
    </div>
};

export default DeathNotice;