import Api from "../helpers/api";

export const getNewCondolences = (data) => {
    return async (dispatch) => {
        dispatch({type: 'GET_NEW_CONCOLENCES', payload: {loading: true, condolences: []}});
        Api.post('get-new-condolences', data).then((response) => {
            if (response.data.success) {
                dispatch(getCondolenceEntriesSuccess(response.data))
            } else {
                dispatch(getCondolenceEntriesError(response.data))
            }
        });
    }
}

export const getCondolenceEntriesSuccess = (data) => {
    return {
        type: 'NEW_CONCOLENCES',
        payload: {
            loading: false,
            success: true,
            condolences: data.condolences,
        }
    }
}

export const getCondolenceEntriesError = () => {
    return {
        type: 'NEW_CONCOLENCES',
        payload: {
            loading: false,
            success: false,
            condolences: [],
        }
    }
}
