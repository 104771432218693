import Api from '../helpers/api'

export const getNewCommemorations = (data) => {
    return (dispatch) => {
        dispatch({
            type: 'GET_NEW_COMMEMORATIONS',
            payload: {
                loading: true,
                commemorations: []
            }
        });
        Api.post('get-new-commemorations?page=' + (data.page), data).then((response) => {
            if (response.data.success) {
                dispatch(getCommemorationsSuccess(response.data))
            } else {
                dispatch(getCommemorationsError(response.data))
            }
        });
    }
}

export const getCommemorationsSuccess = (data) => {
    return {
        type: 'NEW_COMMEMORATIONS',
        payload: {
            loading: false,
            success: true,
            commemorations: data.commemorations
        }
    }
}

export const getCommemorationsError = () => {
    return {
        type: 'NEW_COMMEMORATIONS',
        payload: {
            loading: false,
            success: false,
            commemorations: []
        }
    }
}

