import React from 'react';
import {Link} from 'react-router-dom';
import Fade from '../helpers/fade';
import styles from '../styles/app.scss';

class PageNotFound extends React.Component {
    render() {
        return (
            <Fade>
                <div className={[styles["uk-container"], styles["uk-padding"]].join(' ')}>
                    <div className={styles["divider"]}>
                        <h1 className={styles["nowrap"]}>Seite nicht gefunden</h1>
                    </div>
                    <p>Liebe Besucherin, lieber Besucher,</p>
                    <p>Die angegebene Seite konnte nicht dargestellt werden. Kontrollieren Sie bitte die angegebene URL auf eventuelle Schreibfehler oder gehen Sie auf die <Link to="/">Startseite</Link> und verwenden
                        Sie die Navigation.</p>
                </div>
            </Fade>
        )
    }
}

export default PageNotFound;
