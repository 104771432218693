import Api from '../helpers/api'

export const getEditions = () => {
    return async (dispatch) => {
        dispatch({
            type: 'GET_EDITIONS',
            payload: {
                loading: true,
                data: [],
            }
        });
        Api.post('get-editions').then((response) => {
            if (response.data.success) {
                dispatch(getEditionsSuccess(response.data))
            }
        });
    }
}

export const getEditionsSuccess = (data) => {
    return {
        type: 'EDITIONS',
        payload: {
            loading: false,
            data: data.editions
        }
    }
}