import Api from '../helpers/api'

/**
 * login axios request
 *
 * @param data
 * @returns {Function}
 */
export const loginAction = (data) => {
    return (dispatch) => {
        dispatch({type: 'DO_LOGIN', payload: {loading: true}})
        Api.post('sso/login', {
            domain: window.domain,
            email: data.email,
            password: data.password,
            bee: data.bee,
        }).then((response) => {
            if (response.data.success) {
                remove()
                set(response.data.user_data.data)
                dispatch(loginSuccess(response.data))
            } else {
                remove()
                dispatch(loginFailed(response.data))
            }
        })
    }
}

/**
 * dispatch login data to store
 *
 * @param data
 * @returns {{type: string, payload: {id: *, login: (LoginForm.login|null|string), email: *, salutation: (string|null|*), firstname: (null|string|*), lastname: (string|null|*)}}}
 */
export const loginSuccess = (data) => {
    return {
        type: 'LOGIN',
        payload: {
            id: data.user_data.data.user_id,
            login: data.user_data.data.login,
            hash: data.user_data.data.hash,
            token: data.user_data.data.token,
            firstname: data.user_data.data.firstname,
            lastname: data.user_data.data.lastname,
            email: data.user_data.data.email,
            salutation: data.user_data.data.salutation,
            success: data.success,
            loading: false,
        }
    }
}

/**
 * send message to store
 *
 * @param data
 * @returns {{type: string, payload: {message: *}}}
 */
export const loginFailed = (data) => {
    return {
        type: 'LOGIN',
        payload: {
            id: localStorage.getItem('id'),
            login: localStorage.getItem('login'),
            token: localStorage.getItem('token'),
            hash: data.hash || localStorage.getItem('hash'),
            email: localStorage.getItem('email'),
            salutation: localStorage.getItem('salutation'),
            firstname: localStorage.getItem('firstname'),
            lastname: localStorage.getItem('lastname'),
            success: data.success,
            message: data.message,
            validation: data.validation || null,
            loading: false,

        }
    }
}

export const authAction = () => {
    return {
        type: 'LOGIN',
        payload: {
            id: localStorage.getItem('id'),
            login: localStorage.getItem('login'),
            token: localStorage.getItem('token'),
            hash: localStorage.getItem('hash'),
            email: localStorage.getItem('email'),
            salutation: localStorage.getItem('salutation'),
            firstname: localStorage.getItem('firstname'),
            lastname: localStorage.getItem('lastname'),
            page_number: localStorage.getItem('page_number'),
            deathNoticeDate: localStorage.getItem('deathNoticeDate'),
        }
    }
}


export const set = (userData) => {
    localStorage.setItem('id', userData.user_id)
    localStorage.setItem('login', userData.login)
    localStorage.setItem('token', userData.login)
    localStorage.setItem('hash', userData.login)
    localStorage.setItem('firstname', userData.firstname)
    localStorage.setItem('lastname', userData.lastname)
    localStorage.setItem('email', userData.email)
    localStorage.setItem('salutation', userData.salutation)
    localStorage.setItem('page_number', '1')
    localStorage.setItem('deathNoticeDate', "")
}

export const remove = () => {
    localStorage.removeItem('id')
    localStorage.removeItem('login')
    localStorage.removeItem('token')
    localStorage.removeItem('hash')
    localStorage.removeItem('firstname')
    localStorage.removeItem('lastname')
    localStorage.removeItem('email')
    localStorage.removeItem('salutation')
    localStorage.removeItem('page_number')
    localStorage.removeItem('deathNoticeDate')
}
