import Api from '../helpers/api'

export const getSearchResults = (data) => {
    return (dispatch) => {
        dispatch({type: 'GET_SEARCH_RESULTS', payload: {loading: true, notices: []}});
        Api.post('search?page=' + (localStorage.getItem('page_number')), {
            edition: data.edition,
            pattern: data.pattern,
            startDate: data.startDate,
        }).then((response) => {
            if (response.data.success) {
                dispatch(getSearchResultsSuccess(response.data))
            } else {
                dispatch(getSearchResultsError(response.data))
            }
        }).catch((response) => {
            dispatch(getSearchResultsError(response.data))
        });
    }
};

export const getSearchResultsSuccess = (data) => {
    return {
        type: 'SEARCH_RESULTS',
        payload: {
            loading: false,
            success: true,
            notices: data.notices
        }
    }
};

export const getSearchResultsError = (data) => {
    return {
        type: 'SEARCH_RESULTS_ERROR',
        payload: {
            loading: false,
            success: false,
            message: data
        }
    }
};