import styles from "../styles/app.scss";
import React from "react";

const ApiError = () => {
    return (
        <div className={[styles["uk-text-center"], styles["uk-margin-large-top"], styles["uk-text-bold"]].join(' ')}>
            Beim Laden der Daten ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut...
        </div>
    )
};

export default ApiError;