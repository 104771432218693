import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import moment from 'moment';
import Api from '../../helpers/api';
import history from '../../helpers/history';
import {Link} from 'react-router-dom';
import styles from '../../styles/app.scss';
import Divider from '../../assets/images/divider_cta.png';

import { DatePickerInput } from 'rc-datepicker';

let delayedSearch;

class SearchBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: this.props.pattern || '',
            startDate: this.props.data || '',
            page: 1,
            suggestions: [],
            showSuggestionList: false,
            loadingSuggestionList: false,
            edition: 'all',
        };

        this.closeOnEsc = this.closeOnEsc.bind(this);
    }

    /**
     * show headline
     *
     * @returns {null}
     */
    showHeadline() {
        return (!this.props.hideHeadline) ? <h2>Traueranzeigen suchen</h2> : null;
    }

    /**
     * show divider
     *
     * @returns {null}
     */
    showDivider() {
        try {
            return (!this.props.hideDivider) ?
                <div className="divider leaf uk-visible@s uk-margin-small-bottom"><img src={Divider}/></div> : null;
        } catch(error) {
            console.error(error)
            return null
        }
        //return null
    }

    /**
     * handle change of search text
     * TODO: suggestion list
     *
     * @param event
     */
    inputChangeHandler(event) {
        const value = event.target.value;
        this.setState({searchText: value});

        /*
        if (value.length > 2) {
            this.getSuggestionList(value);
        } else {
            this.setState({suggestions: [], showSuggestionList: false});
        }
        */
    }

    /**
     * handle date change in search form
     * @param date
     */
    handleDateChange(date) {
        this.setState({
            startDate: date
        });
    }

    handleEditionChange(event) {
        event.preventDefault();
        this.setState({edition: event.currentTarget.value});
    }

    /**
     * get suggestion list
     *
     * @param pattern
     */
    getSuggestionList(pattern) {
        let self = this;
        clearTimeout(delayedSearch);
        delayedSearch = setTimeout(function () {
            self.setState({loadingSuggestionList: true});
            Api.post('get-suggestion-list', {
                pattern: pattern,
            }).then((response) => {
                if (response.data.success) {
                    self.setState({suggestions: response.data.notices, showSuggestionList: true});
                    document.addEventListener("keydown", self.closeOnEsc, false);
                    document.addEventListener("mousedown", self.clickOutside, false);
                }
                self.setState({loadingSuggestionList: false});

            });
        }, 500);
    }

    /**
     * list with suggestions
     * TODO: limit maybe?
     *
     * @returns {*}
     */
    suggestionList() {
        if (this.state.showSuggestionList) {
            return (
                <ul className="suggestion-list" style={{width: document.getElementById('pattern').offsetWidth + 'px'}}>
                    {this.state.suggestions.map((suggestion, index) => {
                        return <Link key={index} to={'/traueranzeige/' + suggestion.id} style={{color: '#1c242c'}}>
                            <li>
                                {suggestion.vname} {suggestion.nname}
                                {suggestion.wohnort.length > 0 &&
                                <>, {suggestion.wohnort}</>
                                }
                            </li>
                        </Link>
                    })}
                </ul>
            )
        }
    }

    /**
     * hide suggestion list when click anywhere else but in it
     *
     * @param event
     */
    clickOutside(event) {
        event.preventDefault();
        let found = event.path.find(el => el.className === 'suggestion-list');

        try {
            if (typeof found === 'undefined') {
                this.setState({showSuggestionList: false});
                document.removeEventListener("keydown", this.closeOnEsc, false);
                document.removeEventListener("mousedown", this.clickOutside, false);
            }
        } catch (error) {}
    }

    /**
     * event handler for ESC key
     *
     * @param event
     */
    closeOnEsc(event) {
        if (event.keyCode === 27) {
            this.setState({showSuggestionList: false});
            document.removeEventListener("keydown", this.closeOnEsc, false);
        }
    }

    /**
     * dropdown list with editions
     * @returns {*}
     */
    selectEditions() {
        if (typeof this.props.editions.data !== 'undefined' && this.props.editions.data.length > 0) {
            return (
                <select className="uk-select" onChange={this.handleEditionChange.bind(this)}
                        defaultValue={(typeof this.props.editions.data.filter(item => item.link === this.props.edition)[0] === 'undefined') ? 'all' : this.props.editions.data.filter(item => item.link === this.props.edition)[0].link}>
                    <option key={0} value="all">Alle Landkreise</option>
                    {this.props.editions.data.map((edition, key) => {
                        return (
                            <option key={key} value={edition.link}>{edition.bezeichnung}</option>
                        )
                    })}
                </select>
            )
        }
    }

    /**
     * redirect to Search Component
     *
     * @param event
     */
    search(event) {
        localStorage.setItem('page_number', '1')
        event.preventDefault();
        history.push({
            pathname: '/search',
            state: {
                pattern: this.state.searchText,
                edition: this.state.edition,
                startDate: (moment(this.state.startDate).isValid()) ? moment(this.state.startDate).format('YYYY-MM-DD') : null,
                page: 1
            }
        });
    }

    /**
     * render method
     *
     * @returns {*}
     */
    render() {
        return (
            <div>
                <div className={[styles["uk-container"], styles["uk-padding"]].join(' ')}>
                    {this.showHeadline()}
                    {this.showDivider()}

                    <form className={styles["uk-grid-small"]} data-uk-grid="margin: 0;">
                        <div className={[styles["uk-width-1-1@s"], styles["uk-margin-small-bottom"]].join(' ')}>
                            <div className={styles["uk-position-relative"]}>
                                <input id="pattern" className={styles["uk-input"]} type="text" placeholder="Name" value={this.state.searchText} onChange={this.inputChangeHandler.bind(this)}/>
                                {this.state.loadingSuggestionList &&
                                <div data-uk-spinner={'ratio: 0.5'} className="uk-position-absolute" style={{top: '12px', right: '12px'}}/>
                                }
                            </div>
                            {this.suggestionList()}
                        </div>
                        <div className={[styles["uk-width-1-1@s"], styles["uk-width-1-2@m"], styles["uk-margin-small-bottom"]].join(' ')}>
                            <label className={styles["input-calendar"]} onClick={(e) => e.preventDefault()}>
                                <DatePickerInput
                                    onChange={this.handleDateChange.bind(this)}
                                    value={this.state.startDate}
                                    className='datepicker'
                                    placeholder="Startdatum"
                                />
                            </label>
                        </div>
                        <div className={[styles["uk-width-1-2@m"], styles["uk-width-1-1@s"], styles["uk-margin-small-bottom"]].join(' ')}>
                            {this.selectEditions()}
                        </div>
                        <div className={[styles["uk-width-1-1@s"], styles["uk-margin-small-bottom"], styles["uk-text-right"]].join(' ')}>
                            <button className={[styles["uk-button"], styles["uk-button-primary"], styles["uk-width-1-1@s"], styles["uk-width-1-3@m"]].join(' ')} onClick={this.search.bind(this)}>
                                SUCHEN
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        editions: state.editions,
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(SearchBox);
