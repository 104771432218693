import Api from '../helpers/api'

/**
 *
 * @param data
 * @returns {Function}
 */
export const getDeathNotices = (data) => {
    return (dispatch) => {
        dispatch({type: 'GET_NOTICES', payload: {loading: true, notices: []}});
        Api.post('get-death-notices').then((response) => {
            if (response.data.success) {
                dispatch(getDeathNoticesSuccess(response.data))
            } else {
                dispatch(getDeathNoticesError(response.data))
            }
        });
    }
};

export const getDeathNoticesSuccess = (data) => {
    return {
        type: 'NOTICES',
        payload: {
            loading: false,
            success: true,
            notices:
            data.notices
        }
    }
};

export const getDeathNoticesError = () => {
    return {
        type: 'NOTICES',
        payload: {
            loading: false,
            success: false,
            notices: []
        }
    }
};

export const getDeathNoticeDetails = (data) => {
    return (dispatch) => {
        dispatch({type: 'GET_NOTICE_DETAILS', payload: {loading: true, details: []}});
        Api.post('get-notice-details', {
            id: data
        }).then((response) => {
            if (response.data.success) {
                dispatch(getDeathNoticeDetailsSuccess(response.data))
            } else {
                dispatch(getDeathNoticeDetailsError(response.data))
            }
        });
    }
};

export const getDeathNoticeDetailsSuccess = (data) => {
    return {
        type: 'NOTICE_DETAILS',
        payload: {
            loading: false,
            success: data.success,
            details: data.details,
            id_before: data.id_before,
            id_after: data.id_after,
            hideCondolenceBook: data.hideCondolenceBook,

        }
    }
}

export const getDeathNoticeDetailsError = (data) => {
    return {
        type: 'NOTICE_DETAILS',
        payload: {
            loading: false,
            success: data.success,
            id_before: data.id_before,
            id_after: data.id_after,
            message: 'Diese Anzeige ist nicht verfügbar',
            hideCondolenceBook: false

        }
    }
}


export const getDeathNoticeCondolences = (data) => {
    return (dispatch) => {
        dispatch({type: 'GET_NOTICE_CONDOLENCES', payload: {...data, loading: true}});
        Api.post('get-condolences?page=' + (data.page), {
            condolenceId: data.id,
            paginate: 5
        }).then((response) => {
            if (response.data.success) {
                dispatch(getDeathNoticeCondolencesSuccess(response.data))
            } else {

            }
        });
    }
};

export const getDeathNoticeCondolencesSuccess = (data) => {
    return {
        type: 'NOTICE_CONDOLENCES',
        payload: {
            loading: false,
            condolences: data.condolences
        }
    }
};

export const getDeathNoticeEdition = (data, isBack = false) => {
    if (!isBack) {
        localStorage.setItem('deathNoticeDate', data.date);
        return (dispatch) => {
            dispatch({type: 'GET_EDITiON_NOTICES', payload: {...data, loading: true}});
            Api.post('get-notice-edition', {
                editionId: data.editionId,
                date: data.date,
            }).then((response) => {
                if (response.data.success) {
                    dispatch(getDeathNoticeEditionSuccess(response.data))
                } else {

                }
            });
        }
    } else {
        if (localStorage.getItem('deathNoticeDate') === '' || localStorage.getItem('deathNoticeDate') === null) {
            localStorage.setItem('deathNoticeDate', data.date)
        }
        return (dispatch) => {
            dispatch({type: 'GET_EDITiON_NOTICES', payload: {...data, loading: true}});
            Api.post('get-notice-edition', {
                editionId: data.editionId,
                date: localStorage.getItem('deathNoticeDate'),
            }).then((response) => {
                if (response.data.success) {
                    dispatch(getDeathNoticeEditionSuccess(response.data))
                } else {

                }
            });
        }
    }
};

export const getDeathNoticeEditionSuccess = (data) => {
    return {
        type: 'EDITiON_NOTICES',
        payload: {
            loading: false,
            editionNotices: data.notices,
            date: data.date,
            prev: data.prev,
            next: data.next,
        }
    }
};
